@font-face {
    font-family: 'Gravity';
    src: url('./fonts/gravity/Gravity-Regular.otf') format('otf');
    font-weight: normal;
    font-style: normal;
}

body {
	background: #222222;
	color: #f5f5f5;
	font-family: 'Gravity', sans-serif;
	margin: 0;
	padding: 0;
}
.allbox {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 1rem;
	justify-content: center;
}

.all-year, .all-tech {
	display: flex;
	flex-direction: row;
}

.year-box {
	margin-right: 10px;
}
.tech-box {
	margin-left: 10px;
	white-space: nowrap;
}
.img-box {
	display: flex;
	flex-direction: column;
	border: 1px solid #999;
	border-radius: 10px;
	overflow: hidden;
	background: #333;
	box-shadow: 0px 0px 10px #999;
	height: 300px;
	overflow: hidden;
	position: relative;
}
.year-box, .tech-box {
	display: flex;
	flex-direction: column;
    font-size: clamp(0.875rem, 0.8295rem + 0.2273vw, 1rem);
    font-weight: 0;
    font-style: normal;
    color: #f5f5f5;
    letter-spacing: 1px;
    opacity: .55;
	text-decoration: none;
	cursor: pointer;
}
.year-box:hover, .tech-box:hover, .act {
	opacity: 1;
}
.image-link {
    display: block;
    width: 300px;
    height: 300px;
    overflow: hidden;
    position: relative;
	cursor: pointer; 
}

.image-link .lazy-load-image-background {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-link img {
    max-width: 100%;
    min-height: 300px;
	min-width: 300px;
    object-fit: cover;
}
.img-box .image-link .name-box {
	display: flex;
	align-items: center;
	flex-direction: column;
	background: transparent;
	position: absolute;
	color: #fff;
	font-size: clamp(1rem, 0.7273rem + 1.3636vw, 1.75rem);
	padding: .4rem;
	opacity: 0;
	transition: .5s;
	justify-content: center;
	align-items: center;
	width: 300px;
	height: 300px;
	z-index: 1;
	text-align: center;
}
.img-box .image-link .name-box span {
	font-size: clamp(1rem, 0.831rem + 0.8448vw, 1.125rem);
}
.img-box:hover .image-link .name-box {
	font-size: clamp(1rem, 0.7273rem + 1.3636vw, 1.75rem);
	display: flex;
	align-items: center;
	flex-direction: column;
	color: #fff;
	padding-bottom: .4rem;
	opacity: .8 !important;
}
.img-box:hover .image-link span {
	opacity: .5;
	transition: .5s;
}
.img-box .image-link span img.landscape {
	filter: blur(0);
	transform: scale(1);
	transition: .5s;
}
.img-box:hover .image-link span img.landscape {
	filter: blur(3px);
	transform: scale(1.2);
	transition: .5s;
}
.img-box:hover .image-link .name-box {
	transition: .8s;
	display: flex;
	opacity: 1;
}
.img-box:hover .image-link .name-box span {
	opacity: .8 !important;
	text-align: center;
}
nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
    padding: 20px 20px 0 20px;
}
nav img {
	max-height: 50px;
}
nav ul {
    list-style: none;
    padding: 0;
    display: flex;
}

nav ul li {
    margin-right: 15px;
	display: flex;
	align-items: center;
}

nav ul li a {
    font-size: clamp(0.875rem, 0.7841rem + 0.4545vw, 1.125rem);
    font-weight: 0;
    font-style: normal;
    color: #f5f5f5;
    letter-spacing: 1px;
    opacity: .55;
	line-height: 3.3em;
	text-decoration: none;
}
nav ul li a:hover {
	opacity: .9;
}
nav ul li .active {
    opacity: 1;
}

h1 {
	font-size: clamp(1.125rem, 0.5341rem + 2.9545vw, 2.75rem);
	background-color: #252525;
	display: block;
    position: relative;
    border-top: 2px solid #333333;
    border-bottom: 2px solid #333333;
    padding: 65px 70px;
    /* margin-bottom: 65px; */
    text-align: left;
}

.content {
	max-width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 10px;
}
.content .allbox {
	max-width: 1620px;
	width: 100%;
	display: flex;
}
.content .filter {
	max-width: 1620px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 2.5rem;
}


footer {
	font-size: clamp(0.75rem, 0.7045rem + 0.2273vw, 0.875rem);
    background-color: #252525;
    display: block;
    border-top: 2px solid #333333;
    border-bottom: 2px solid #333333;
    padding: 15px 20px;
    text-align: left;
	margin-top: 4rem;
}
footer div {
	color: #f5f5f5;
	opacity: .55;
	display: flex;
	justify-content: space-between;
}
.slide-box-two {
	display: flex;
	justify-content: center;
	align-items: center;
}
.box-pics {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
}
.text {
	padding: 0 30px 30px 30px;
	/* max-height: 80%; */
	max-width: 1420px;
	width: 100%;
}
.slide-box-tree {
    position: fixed;
    top: 0;
    right: -100%;
    width: 90%;
	max-width: 600px;
    height: auto;
    background: #000;
    transition: right 0.5s ease-in-out;
    z-index: 10;
	top: 10%;
	border: 2px solid #333333;
	border-bottom-left-radius: 20px;
	border-top-left-radius: 20px;
	overflow: auto;
    max-height: 80Vh;
}
.sold {
	background: red;
	color: white;
    transform: rotate(45deg);
    z-index: 2;
    position: absolute;
    right: -18px;
    padding: 0px 24px;
    top: 14px;
	box-shadow: 0px 0px 10px #999;
	text-shadow: 1px 1px 2px #333;
}
.free {
	background: green;
	color: white;
    transform: rotate(45deg);
    z-index: 2;
    position: absolute;
    right: -18px;
    padding: 0px 24px;
    top: 14px;
	box-shadow: 0px 0px 10px #999;
	text-shadow: 1px 1px 2px #333;
}
.reserved {
	background: #007bff;
	color: white;
    transform: rotate(45deg);
    z-index: 2;
    position: absolute;
    right: -24px;
    padding: 0px 24px;
    top: 26px;
	box-shadow: 0px 0px 10px #999;
	text-shadow: 1px 1px 2px #333;
}


.lb-dataContainer {
	position: relative;
}
.lb-details {
	position: absolute;
    top: -150%;
    background: #0000009c;
    width: calc(100% - 14px) !important;
    padding: 6px;
    left: 1px;
}


.open-text {
    position: absolute;
    opacity: .5;
    top: 11%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border: 1px solid #fff;
	right: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
	background-color: #000;
}
.open-text:hover {
	opacity: 1;
	cursor: pointer;
}
.close-text {
	position: absolute;
    right: 20px;
	opacity: .5;
	width: 10px;
	height: 10px;
}
.close-text:hover {
	opacity: 1;
	cursor: pointer;
}

.slide-box-tree.visible {
    right: 0;
	margin-left: -2px;
}

.slide-box-tree.hidden {
    right: -100%;
}

.text p {
	font-size: 14px;
    font-style: normal;
    line-height: 1.75em;
    color: #d5d5d5;
}


/* styles.css */
.scroll-to-top {
    position: fixed;
    bottom: 50px;
    right: 30px;
    z-index: 1000;
}
@media (max-width: 600px) {
	.scroll-to-top {
		display: none;
	}
}

.scroll-to-top-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 24px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.scroll-to-top-button:hover {
    background-color: #0056b3;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #101010f2;
    border: 1px solid #3b3b3b;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #3b3b3b;
    padding: 20px;
    position: relative;
    max-width: 80%;
    max-height: 80%;
    overflow: auto;
	display: flex;
}
.modal-content img {
	margin-right: 20px;
}
.modal-content h2 {
	margin: 0 0 10px 0;
	font-size: clamp(1rem, 0.7273rem + 1.3636vw, 1.75rem);
}
.modal-content div {
	font-size: clamp(0.875rem, 0.8295rem + 0.2273vw, 1rem);
}

.close-button {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
	opacity: .5;
}
.close-button:hover {
	opacity: 1;
}

.modal-img {
	width: 100%;
	max-width: 30vw;
}

.modal-box {
	position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    z-index: 2;
    background: #00000091;
    padding: 10px;
	cursor: pointer;
	font-size: clamp(0.875rem, 0.8295rem + 0.2273vw, 1rem);
	font-weight: 600;
}
.modal-box:hover {
	background: #000000cc;
}
.btn-send {
	font-size: clamp(0.875rem, 0.8295rem + 0.2273vw, 1rem);
	padding: 5px 10px;
	color: #fff;
	border-radius: 5px;
	background: gray;
	display: inline-block;
}

.modal-content .tags {
	font-size: clamp(0.625rem, 0.4871rem + 0.6897vw, 0.875rem);
	margin:6px 0;
	color: #5e5d5d;
}
.modal-content .descr {
	font-size: clamp(0.875rem, 0.8295rem + 0.2273vw, 1rem);
}

.lb-outerContainer {
	background-color: transparent !important;
}

.lb-container {
	background-color: #101010f2 !important;
	border: 1px solid #3b3b3b;
	border-radius: 10px !important;
	box-shadow: 0 0 10px #3b3b3b;
	padding: 10px;
}

.lightbox .lb-image {
	border: 0 !important;
}


.consentBanner {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: #000000b7;
	color: #fff;
	padding: 15px;
	text-align: center;
	z-index: 1000;
}
.acceptButton {
	margin-left: 10px;
	padding: 6px 15px;
	background: #007bff;
	color: #ffffff;
	border: none;
	cursor: pointer;
	border-radius: 5px;
}
.acceptButton:hover {
	background: #0056b3;
	color: #fff;
}
.link {
	color: #fff;
	text-decoration: underline;
	opacity: 0.55;
}
.link:hover {
	opacity: 1;
}

@media (max-width: 1400px) {
	.content .filter {
		flex-direction: column;
		justify-content: center;
	}
	.all-year, .all-tech {
		justify-content: center;
		flex-wrap: wrap;
	}
}

@media (max-width: 920px) {
	.tech-box {
		align-items: center;
	}
	h1 {
		padding: 2rem;
	}
}
@media (max-width: 600px) {
	nav {
		flex-direction: column;
	}
	.content {
		padding: 0 5px;
	}
	.img-box {
		height: 150px;
	}
	.image-link {
		width: 150px;
		height: 150px;
	}
	.image-link img {
		min-height: 150px;
		min-width: 150px;
	}
	.modal-content {
		flex-direction: column;
	}
	.modal-content h2 {
		margin: .6rem 0;
	}
	.modal-content img {
		margin-right: 0;
		max-height: 60vh;
	}
	.close-button {
		top: -1px;
		right: 4px;
	}
	.modal-img {
		max-width: 350px;
	}
	.consentBanner {
		width: auto;
	}
	footer div {
		flex-direction: column;
	}
}

.telegram {
	display: block;
	background: url(/public/img/telegram.png);
	width: 20px;
	height: 20px;
	background-size: contain;
}